const Button = (props) => {
  const { onClick, className, children, ...otherProps } = props;
  return (
    <button
      className={className || "base-button"}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
