import axios from "axios";
import moment from "moment";
const apiToken = process.env.API_TOKEN || false;
const host =
  process.env.API_URL.search("127.0.0.1:1337") >= 0 ? "http://" : "https://";
export const apiUrl = host + process.env.API_URL;

export const paratyUrl = {
  es: "https://golden-parnassus-dot-secure-mexico2.appspot.com/booking1?language=SPANISH&",
  en: "https://golden-parnassus-dot-secure-mexico2.appspot.com/booking1?language=ENGLISH&",
  // fr: "https://fr.goldenparnassusresortspa.com//bookcore/availability/goldenparnassus",
  // pt: "https://pt.goldenparnassusresortspa.com/bookcore/availability/goldenparnassus",
};

const today = new Date();
const startDate = new Date(today.valueOf() + 86400000);
const endDate = new Date(startDate.valueOf() + 86400000);

export const getbookingUrl = (locale, code) => {
  let url = "";
  if (locale && code) {
    // url = paratyUrl[locale]  + "roomFilter=" + code;
    url = paratyUrl[locale] + "adultsRoom1=2"
  } else {
    url = "/";
  }
  return url;
};

export const packagesUrl = {
  es: "https://paquetes.goldencancun.com",
  en: "https://packages.goldencancun.com",
  fr: "https://packages.goldencancun.com",
  pt: "https://packages.goldencancun.com",
};

export const cleanUrl = (url) => {
  if (url && url.search(apiUrl) >= 0) {
    return url.replace(document.location.href, "");
  }
  return url;
};

export const apiRooms = apiUrl + "/api/rooms";
export const apiRoomCategories = apiUrl + "/api/room-categories";
export const apiPromotions = apiUrl + "/api/promotions";
export const apiBlogs = apiUrl + "/api/blogs";
export const apiBlogsCategories = apiUrl + "/api/blog-categories";
export const apiPhotos = apiUrl + "/api/photos";
export const apiPhotoCategories = apiUrl + "/api/photo-categories";
export const apiEmail = apiUrl + "/api/emails";
export const apiSliders = apiUrl + "/api/sliders";
export const apiHappenings = apiUrl + "/api/happenings";
export const apiEmailError = apiUrl + '/api/smtp-errors'

export const axiosGet = async (url, query) => {
  const response = await axios
    .get(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    .then((res) => {
      return normalize(res?.data?.data || []);
    })
    .catch((error) => {
      return [];
    });
  return response;
};

export const axiosPost = async (url, query) => {
  const response = await axios
    .post(url, query, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return response;
};

export const normalize = (data) => {
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";
  const isArray = (data) =>
    Object.prototype.toString.call(data) === "[object Array]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (isArray(data)) {
    return data.map((item) => normalize(item));
  }

  if (isObject(data)) {
    if (isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = normalize(data[key]);
    }

    return data;
  }

  return data;
};
