import ContentErrorStatic from '../organisms/ContentErrorStatic'
import React from 'react'
import { apiEmailError, axiosPost } from '@/utils/api'

const ignoreErrors = process.env.ENV_IGNORE_ERRORS
const sendError = async error => {
  const date = new Date()
  const data = {
    error,
    date
  }
  const response = await axiosPost(apiEmailError, {
    data: data
  })
  if (response.status === 200 || response.statusText === 'OK') {
    console.log('message sent successfully ')
  } else {
    console.log('something is wrong and the message was not sent correctly')
  }
  console.log(error, date,)
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    !ignoreErrors && sendError(error.message)
    console.log({ error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ContentErrorStatic />
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary
