const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh", // Establece la altura al 100% de la ventana
      textAlign: "center",
      fontFamily: "Arial, sans-serif",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "4rem",
    },
    text: {
      fontSize: "1.2rem",
      color: "#666",
      // Centrar verticalmente el mensaje
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  };
  
  const Suspended = () => {
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>Sitio Suspendido</h1>
        <p style={styles.text}>Lamentablemente, este sitio ha sido suspendido.</p>
      </div>
    );
  };
  
  export default Suspended;
  