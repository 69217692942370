import "@/styles/globals.css";
import { appWithTranslation } from "next-i18next";
import localFont from "@next/font/local";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Layout from "@/components/templates/Layout";
import ErrorBoundary from "@/components/templates/ErrorBoundary";
import Suspended from "@/components/templates/Suspended";

const poppins = localFont({
  src: [
    {
      path: "./fonts/Poppins/Poppins-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "./fonts/Poppins/Poppins-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "./fonts/Poppins/Poppins-Medium.ttf",
      weight: "500",
      style: "normal",
    },
  ],
  weight: ["300", "400", "500"],
  subsets: ["latin"],
  variable: "--font-poppins",
});

const barlow_condensed = localFont({
  src: [
    {
      path: "./fonts/BarlowCondensed/BarlowCondensed-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "./fonts/BarlowCondensed/BarlowCondensed-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "./fonts/BarlowCondensed/BarlowCondensed-Bold.ttf",
      weight: "700",
      style: "normal",
    },
  ],
  weight: ["400", "500", "700"],
  subsets: ["latin"],
  variable: "--font-barlow_condensed",
});

const nunito = localFont({
  src: [
    {
      path: "./fonts/Nunito/Nunito-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "./fonts/Nunito/Nunito-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "./fonts/Nunito/Nunito-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "./fonts/Nunito/Nunito-Bold.ttf",
      weight: "700",
      style: "normal",
    },
  ],
  weight: ["400", "500", "700"],
  subsets: ["latin"],
  variable: "--font-nunito",
});

const isSuspended = false

const App = ({ Component, pageProps }) => {
  return isSuspended ? 
    <Suspended /> :
    <ErrorBoundary>
      <Layout>
        <main
          className={`${poppins.variable} ${barlow_condensed.variable} ${nunito.variable}`}
        >
          <Component {...pageProps} />
        </main>
      </Layout>
    </ErrorBoundary>
};
export default appWithTranslation(App);
