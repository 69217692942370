import { useRouter } from "next/router";

const Layout = ({ children }) => {
  const { locale } = useRouter();
  return (
    <>
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3512319,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        <script async src="https://golden-parnassus-dot-secure-mexico2.appspot.com/parnassusscript"/>
      </div>
      <main>{children}</main>
    </>
  );
};
export default Layout;
